import React, { useEffect, useState, useContext } from "react";
import apiClient from "../utils/axiosConfig";
import TrialTab from "./trial_tab";
import { useAuth } from '../dataContext/AuthContext';
import ActiveTab from "./active_tab";
import ExpiredTab from "./expired_tab";
import RenewTab from "./renew_tab";
import SuspendedTab from "./suspended_tab";
import PaymentInProgressTab from "./payment_in_progress_tab";
import PaginationButtons from "./pagination_buttons";
import CmsOverview from "./cms_overview";
import { useNavigate } from 'react-router-dom';
import CmsActivationModal from "./cms_activation_modal";

function CmsMainContent() {
  const [selectedStatus, setSelectedStatus] = useState({name: "Expired", id: 3});
  const [statuses, setStatuses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [cmsRecords, setCmsRecords] = useState([]);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [statistics, setStatistics] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { organizationId, roles } = useAuth();
  const navigate = useNavigate();

  const fetchStatus = async () => {
    try {
      const status = await apiClient.get(`${apiBaseUrl}/cms/allStatus`);
      if (status) {
        setStatuses(status.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (isReload) {
      window.location.reload();
      setIsReload(false);
    }
  }, [isReload]);

  const fetchStatistics = async () => {
    try {
      const response = await apiClient.get(`${apiBaseUrl}/cms/gateways/analysis?organizationId=${organizationId}`);
      if (response) {
        setStatistics(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handlePageChange = (pageNumber) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentPage(pageNumber); 
      fetchRecords(selectedStatus.id, pageNumber, pageSize, searchTerm);
      setIsTransitioning(false);
    }, 300);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setIsSearching(true);
    setCurrentPage(1);
    
    // Debounce the search to avoid too many API calls
    const timeoutId = setTimeout(() => {
      fetchRecords(selectedStatus.id, 1, pageSize, value);
      setIsSearching(false);
    }, 500);

    return () => clearTimeout(timeoutId);
  };

  const renderTabContent = (records) => {
    switch (selectedStatus.name) {
      case "Active":
        return (
          <ActiveTab
            records={records}
            currentPage={currentPage}
            pageSize={pageSize}
          />
        );
      case "Expired":
        return (
          <ExpiredTab
            records={records}
            currentPage={currentPage}
            pageSize={pageSize}
          />
        );
      case "Renew":
        return (
          <RenewTab
            records={records}
            currentPage={currentPage}
            pageSize={pageSize}
          />
        );
      case "Suspended":
        return (
          <SuspendedTab
            records={records}
            currentPage={currentPage}
            pageSize={pageSize}
          />
        );
      case "Payment in Progress":
        return (
          <PaymentInProgressTab
            records={records}
            currentPage={currentPage}
            pageSize={pageSize}
          />
        );
    }
  };

  const handleStatusChange = (status) => {
    setSelectedStatus({name: status.statusName, id: status.statusId});
    setCurrentPage(1);
    setSearchTerm(""); // Reset search when changing status
    fetchRecords(status.statusId, 1, pageSize, "");
  };

  const fetchRecords = async (statusId, pageNumber, pageSize, search = "") => {
    try {
      const records = await apiClient.get(
        `${apiBaseUrl}/cms/cmsrecords?organizationId=${organizationId}&statusId=${statusId}&pageNumber=${pageNumber}&pageSize=${pageSize}&searchTerm=${search}`
      );
      
      if(records){
        setCmsRecords(records.data.data.records);
        setCurrentPage(records.data.data.pageNumber);
        setPageSize(records.data.data.pageSize);
        setTotalPage(records.data.data.totalPage);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchStatus();
    fetchStatistics();
  }, []);

  return (
    <div className="flex-col">
      <div className="flex-row">
      <button
        onClick={() => navigate("/cms/orders")}
        className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 mr-4"
      >
        View Orders
      </button>
      {roles.includes("super_admin") && (
      <button
        onClick={() => navigate("/cms/model")}
        className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 mr-4"
      >
        Manage Subscripton Models
      </button>)}
      </div>
      
      <CmsOverview statistics={statistics} />
      

      <div className="mt-4 p-4 border border-gray-200 rounded-lg shadow-sm bg-white">
        <div className="flex flex-row justify-center items-center">
          <h2 className="text-xl font-bold text-blue-600 mt-4 mr-auto">
            CMS Gateway
          </h2>
          <CmsActivationModal setIsReload={setIsReload} />
        </div>
        {/* Status tabs and search bar */}
        <div className="flex justify-between items-center p-4 border-b border-gray-200">
          <div className="flex space-x-8">
            {statuses.map((tab) => (
              <button
                key={tab.statusName}
                className={`py-2 text-sm font-medium transition-colors duration-300
                  ${
                    selectedStatus.name === tab.statusName
                      ? "text-blue-600 border-b-2 border-blue-600"
                      : "text-gray-500"
                  }
                  hover:text-blue-600`}
                onClick={() => handleStatusChange(tab)}
              >
                {tab.statusName}
              </button>
            ))}
          </div>
          <div className="relative">
            <input
              type="text"
              placeholder="Search by Serial Number..."
              value={searchTerm}
              onChange={handleSearch}
              className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-64"
            />
            {isSearching && (
              <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-blue-500"></div>
              </div>
            )}
          </div>
        </div>
        {/* Table content */}
        <div className="p-4">{renderTabContent(cmsRecords)}</div>

        {/* Pagination */}
        <div className="border-t border-gray-200 p-4">
          <PaginationButtons
            currentPage={currentPage}
            totalPage={totalPage}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}

export default CmsMainContent;