import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { FaKey, FaUser } from "react-icons/fa";
import { FiCopy } from "react-icons/fi";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import apiClient from "../utils/axiosConfig";
import { useAuth } from "../dataContext/AuthContext";
import UserRow from "./user_row";
import ApiKeyRow from "./key_row";

function SettingsContent() {
  return (
    <div className="p-6 w-full bg-white shadow-lg rounded-lg max-w-3xl mx-auto">
      {/* Option Item */}
      <ApiKeyRow />
      <UserRow/>
    </div>
  );
}
function CmsOrganizationRow() {
  
}
export default SettingsContent;
