import React, { useState, useEffect } from "react";
import { FaUser } from "react-icons/fa";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import Modal from "react-modal";
import apiClient from "../utils/axiosConfig";

const OrganizationForm = ({
  userOrganization,
  setUserOrganization,
  organizations,
  errorMessage,
  onSubmit,
  isPrimaryUser,
  setIsPrimaryUser,
}) => (
  <form onSubmit={onSubmit} className="space-y-4">
    <div>
      <label className="block text-sm font-medium text-gray-700">
        Select Organization
      </label>
      <select
        value={userOrganization}
        onChange={(e) => setUserOrganization(e.target.value)}
        className="w-full px-3 py-2 border border-gray-300 rounded-md"
        required
      >
        <option value="">Select organization</option>
        {organizations.map((org) => (
          <option key={org.organizationId} value={org.organizationId}>
            {org.organizationName}
          </option>
        ))}
      </select>
    </div>
    <div className="space-y-2">
        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            id="isPrimaryUser"
            checked={isPrimaryUser}
            onChange={(e) => setIsPrimaryUser(e.target.checked)}
            className="rounded border-gray-300 text-blue-500 focus:ring-blue-500"
          />
          <label htmlFor="isPrimaryUser" className="text-sm font-medium text-gray-700">
            Primary User
          </label>
        </div>
        <p className="text-sm text-gray-500 italic">
          1 organization can only have 1 primary user. Primary user will receive the monthly invoices of CMS subscription.
        </p>
      </div>
    {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}
    <button
      type="submit"
      className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-md"
    >
      Next
    </button>
  </form>
);
  
  const UserDetailsForm = ({
    firstName,
    setFirstName,
    lastName,
    setLastName,
    userEmail,
    setUserEmail,
    countryCode,
    setCountryCode,
    phoneNumber,
    setPhoneNumber,
    countryCodes,
    errorMessage,
    onBack,
    onSubmit
  }) => (
    <form onSubmit={onSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          First Name
        </label>
        <input
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Last Name
        </label>
        <input
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Email
        </label>
        <input
          type="email"
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Contact Number
        </label>
        <div className="flex space-x-2">
          <select
            value={countryCode}
            onChange={(e) => setCountryCode(e.target.value)}
            className="w-1/3 px-3 py-2 border border-gray-300 rounded-md"
            required
          >
            <option value="">Code</option>
            {countryCodes.map((country) => (
              <option key={country.code} value={country.code}>
                {country.code} ({country.country})
              </option>
            ))}
          </select>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value.replace(/[^\d-\s()]/g, ''))}
            className="w-2/3 px-3 py-2 border border-gray-300 rounded-md"
            required
          />
        </div>
      </div>
      {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}
      <div className="flex space-x-2">
        <button
          type="button"
          onClick={onBack}
          className="w-1/2 bg-gray-500 hover:bg-gray-600 text-white font-semibold px-4 py-2 rounded-md"
        >
          Back
        </button>
        <button
          type="submit"
          className="w-1/2 bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-md"
        >
          Next
        </button>
      </div>
    </form>
  );
  
  const PasswordForm = ({
    userPassword,
    setUserPassword,
    retypePassword,
    setRetypePassword,
    isPasswordSame,
    errorMessage,
    onBack,
    onSubmit
  }) => (
    <form onSubmit={onSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Password
        </label>
        <input
          type="password"
          value={userPassword}
          onChange={(e) => setUserPassword(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Retype Password
        </label>
        <input
          type="password"
          value={retypePassword}
          onChange={(e) => setRetypePassword(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          required
        />
      </div>
      {!isPasswordSame && (
        <p className="text-red-500 text-sm">Passwords must match!</p>
      )}
      {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}
      <div className="flex space-x-2">
        <button
          type="button"
          onClick={onBack}
          className="w-1/2 bg-gray-500 hover:bg-gray-600 text-white font-semibold px-4 py-2 rounded-md"
        >
          Back
        </button>
        <button
          type="submit"
          disabled={!isPasswordSame}
          className={`w-1/2 ${
            isPasswordSame
              ? "bg-blue-500 hover:bg-blue-600"
              : "bg-gray-500"
          } text-white font-semibold px-4 py-2 rounded-md`}
        >
          Next
        </button>
      </div>
    </form>
  );
  
  const RoleForm = ({
    userRole,
    setUserRole,
    rolesFromApi,
    errorMessage,
    isLoading,
    onBack,
    onSubmit
  }) => (
    <form onSubmit={onSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          User Role
        </label>
        <select
          value={userRole}
          onChange={(e) => setUserRole(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          required
        >
          <option value="">Select role</option>
          {rolesFromApi.map((role) => (
            <option key={role.name} value={role.name}>
              {role.name}
            </option>
          ))}
        </select>
      </div>
      {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}
      <div className="flex space-x-2">
        <button
          type="button"
          onClick={onBack}
          className="w-1/2 bg-gray-500 hover:bg-gray-600 text-white font-semibold px-4 py-2 rounded-md"
        >
          Back
        </button>
        <button
          type="submit"
          disabled={isLoading}
          className="w-1/2 bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-md"
        >
          {isLoading ? "Creating..." : "Create User"}
        </button>
      </div>
    </form>
  );

  
const UserRow = () => {
  // Form state
  const [currentStep, setCurrentStep] = useState(1);
  const [isShowUserDrop, setIsShowUserDrop] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Organization state
  const [organizations, setOrganizations] = useState([]);
  const [userOrganization, setUserOrganization] = useState("");
  const [isOrganizationValid, setIsOrganizationValid] = useState(false);

  // User details state
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [isPrimaryUser, setIsPrimaryUser] = useState(false);

  // Password state
  const [userPassword, setUserPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [isPasswordSame, setIsPasswordSame] = useState(false);

  // Role state
  const [userRole, setUserRole] = useState("");
  const [rolesFromApi, setRolesFromApi] = useState([]);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const countryCodes = [
    // ASEAN Region (Priority)
    { code: "+60", country: "Malaysia" },
    { code: "+65", country: "Singapore" },
    { code: "+62", country: "Indonesia" },
    { code: "+66", country: "Thailand" },
    { code: "+63", country: "Philippines" },
    { code: "+84", country: "Vietnam" },
    { code: "+673", country: "Brunei" },
    { code: "+855", country: "Cambodia" },
    { code: "+856", country: "Laos" },
    { code: "+95", country: "Myanmar" },
  
    // Major Asian Countries
    { code: "+86", country: "China" },
    { code: "+852", country: "Hong Kong" },
    { code: "+91", country: "India" },
    { code: "+81", country: "Japan" },
    { code: "+82", country: "South Korea" },
    { code: "+886", country: "Taiwan" },
  
    // English Speaking Countries
    { code: "+1", country: "USA/Canada" },
    { code: "+44", country: "United Kingdom" },
    { code: "+61", country: "Australia" },
    { code: "+64", country: "New Zealand" },
    { code: "+353", country: "Ireland" },
  
    // Middle East
    { code: "+971", country: "UAE" },
    { code: "+966", country: "Saudi Arabia" },
    { code: "+974", country: "Qatar" },
    { code: "+973", country: "Bahrain" },
    { code: "+965", country: "Kuwait" },
    
    // European Countries
    { code: "+49", country: "Germany" },
    { code: "+33", country: "France" },
    { code: "+39", country: "Italy" },
    { code: "+34", country: "Spain" },
    { code: "+31", country: "Netherlands" },
    { code: "+46", country: "Sweden" },
    { code: "+47", country: "Norway" },
    { code: "+45", country: "Denmark" },
    { code: "+358", country: "Finland" },
    { code: "+48", country: "Poland" }
  ].sort((a, b) => a.country.localeCompare(b.country));
  
  useEffect(() => {
    if (userPassword !== retypePassword) {
      setIsPasswordSame(false);
    } else {
      setIsPasswordSame(true);
    }
  }, [userPassword, retypePassword]);

  useEffect(() => {
    if (isUserModalOpen && currentStep === 1) {
      fetchOrganizations();
    }
  }, [isUserModalOpen]);

  useEffect(() => {
    setErrorMessage("");
    if (currentStep === 4) {
      fetchRoles();
    }
  }, [currentStep]);

  const validateOrganization = async (orgId) => {
    try {
      const response = await apiClient.get(
        `${apiBaseUrl}/Auth/organizations/${orgId}/accounts`
      );
      const isValid = response.data.data.length < 2;
      setIsOrganizationValid(isValid);
      return isValid;
    } catch (err) {
      setErrorMessage("Failed to validate organization quota");
      return false;
    }
  };

  const fetchOrganizations = async () => {
    try {
      const response = await apiClient.get(`${apiBaseUrl}/Cms/organizations`);
      setOrganizations(response.data.data);
    } catch (err) {
      setErrorMessage("Failed to fetch organizations");
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await apiClient.get(`${apiBaseUrl}/Auth/getRoles`);
      setRolesFromApi(response.data.roles);
    } catch (err) {
      setErrorMessage("Failed to fetch roles");
    }
  };

  const handleOrganizationSubmit = async (e) => {
    e.preventDefault();
    const isValid = await validateOrganization(userOrganization);
    if (isValid) {
      setCurrentStep(2);
    }
    else{
        setErrorMessage("Current organization already has more than 2 accounts. Please contact administrator to proceed.");
    }
  };

  const handleUserDetailsSubmit = (e) => {
    e.preventDefault();
    setCurrentStep(3);
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (isPasswordSame) {
      setCurrentStep(4);
    }
  };

  const handleFinalSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const dto = {
        email: userEmail,
        password: userPassword,
        role: userRole,
        organizationId: userOrganization,
        firstName: firstName,
        lastName: lastName,
        contactNumber: `${countryCode}${phoneNumber.replace(/[-\s()]/g, "")}`,
        isPrimaryUser: isPrimaryUser
      };

      await apiClient.post(`${apiBaseUrl}/Auth/createNewUser`, dto);
      alert("User created successfully!");
      handleModalClose();
    } catch (err) {
      setErrorMessage(err.response.data);
    }
    setIsLoading(false);
  };

  const handleModalClose = () => {
    setIsUserModalOpen(false);
    setCurrentStep(1);
    // Reset all form fields
    setUserOrganization("");
    setFirstName("");
    setLastName("");
    setCountryCode("");
    setPhoneNumber("");
    setUserEmail("");
    setUserPassword("");
    setRetypePassword("");
    setUserRole("");
    setErrorMessage("");
    setIsPrimaryUser(false); 
  };

  return (
    <div className="flex flex-col items-center justify-start p-4 border rounded-lg mt-4">
      <div className="flex flex-row justify-start w-full">
        <div className="flex items-center space-x-3">
          <FaUser className="text-blue-500 text-2xl" />
          <div>
            <p className="text-lg font-medium">User Management</p>
          </div>
        </div>
        <button
          onClick={() => setIsShowUserDrop(!isShowUserDrop)}
          className="text-2xl font-semibold text-blue-500 flex items-center ml-auto"
        >
          {isShowUserDrop ? <FiChevronUp /> : <FiChevronDown />}
        </button>
      </div>

      {isShowUserDrop && (
        <div className="flex flex-col w-full">
          <div className="flex flex-row pr-12 pl-12 mt-3 justify-start w-full">
            <div className="flex items-center space-x-3">
              <p className="text-lg font-medium">Create User</p>
            </div>
            <button
              onClick={() => setIsUserModalOpen(true)}
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-0 rounded-md ml-auto h-8"
            >
              Create
            </button>
          </div>
        </div>
      )}

      <Modal
        isOpen={isUserModalOpen}
        onRequestClose={handleModalClose}
        className="flex items-center justify-center h-full"
        overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg w-[400px]">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold">Create User - Step {currentStep}/4</h3>
          </div>
          
          {currentStep === 1 && (
            <OrganizationForm
              userOrganization={userOrganization}
              setUserOrganization={setUserOrganization}
              organizations={organizations}
              errorMessage={errorMessage}
              onSubmit={handleOrganizationSubmit}
              isPrimaryUser={isPrimaryUser}
              setIsPrimaryUser={setIsPrimaryUser}
            />
          )}
          {currentStep === 2 && (
            <UserDetailsForm
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              userEmail={userEmail}
              setUserEmail={setUserEmail}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              countryCodes={countryCodes}
              errorMessage={errorMessage}
              onBack={() => setCurrentStep(1)}
              onSubmit={handleUserDetailsSubmit}
            />
          )}
          {currentStep === 3 && (
            <PasswordForm
              userPassword={userPassword}
              setUserPassword={setUserPassword}
              retypePassword={retypePassword}
              setRetypePassword={setRetypePassword}
              isPasswordSame={isPasswordSame}
              errorMessage={errorMessage}
              onBack={() => setCurrentStep(2)}
              onSubmit={handlePasswordSubmit}
            />
          )}
          {currentStep === 4 && (
            <RoleForm
              userRole={userRole}
              setUserRole={setUserRole}
              rolesFromApi={rolesFromApi}
              errorMessage={errorMessage}
              isLoading={isLoading}
              onBack={() => setCurrentStep(3)}
              onSubmit={handleFinalSubmit}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default UserRow;
