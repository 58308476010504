import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

const CmsOverview = ({ statistics }) => {
  const COLORS = {
    Active: '#10B981', // Emerald green - more intuitive for active status
    'About to Expire': '#FBBF24', // Warm yellow - warning color
    Expired: '#EF4444', // Red - clear indication of expired
    Suspended: '#6B7280' // Gray - neutral for suspended
  };

  const pieData = [
    { name: 'Active', value: statistics?.activeCount || 0 },
    { name: 'About to Expire', value: statistics?.renewCount || 0 },
    { name: 'Expired', value: statistics?.expiredCount || 0 },
    { name: 'Suspended', value: statistics?.suspendedCount || 0 }
  ].filter(item => item.value > 0);

  const total = pieData.reduce((sum, item) => sum + item.value, 0);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="bg-white p-2 shadow-lg rounded-lg border border-gray-200">
          <p className="font-medium text-gray-800">{data.name}</p>
          <p className="text-gray-600">Count: {data.value}</p>
          <p className="text-gray-600">
            Percentage: {((data.value / total) * 100).toFixed(1)}%
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm mb-6">
      <h2 className="text-lg font-semibold mb-4">Overview</h2>
      <div className="flex justify-between items-start">
        {/* Table */}
        <div className="w-1/2">
          <table className="w-full">
            <tbody>
              {pieData.map(item => (
                <tr key={item.name} className="group hover:bg-gray-50">
                  <td className="py-2 flex items-center">
                    <span 
                      className="w-3 h-3 rounded-full mr-2" 
                      style={{ backgroundColor: COLORS[item.name] }}
                    />
                    {item.name}
                  </td>
                  <td className="text-right font-medium">{item.value}</td>
                  <td className="text-right px-4 text-gray-600">
                    {((item.value / total) * 100).toFixed(1)}%
                  </td>
                </tr>
              ))}
              <tr className="border-t border-gray-200">
                <td className="py-3 font-medium">Total</td>
                <td className="text-right font-medium">{total}</td>
                <td className="text-right px-4 font-medium">100%</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Enhanced Pie Chart */}
        <div className="w-1/2 h-64">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={pieData}
                cx="50%"
                cy="50%"
                outerRadius={90}
                innerRadius={60}
                dataKey="value"
                paddingAngle={2}
              >
                {pieData.map((entry, index) => (
                  <Cell 
                    key={`cell-${index}`} 
                    fill={COLORS[entry.name]}
                    stroke="white"
                    strokeWidth={2}
                  />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default CmsOverview;