import React, { useState } from "react";
import { AlertCircle, ChevronDown, ChevronRight, User } from 'lucide-react';
import apiClient from "../utils/axiosConfig";
function ActiveTab({records, currentPage, pageSize}) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [isSuspending, setIsSuspending] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const handleRowSelect = (id) => {
    const newSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];
    setSelectedRows(newSelectedRows);
  };

  const handleSuspend = async () => {
    if (selectedRows.length === 0) {
      alert("Please select at least one gateway to suspend");
      return;
    }

    if (!window.confirm("Are you sure you want to suspend the selected gateways?")) {
      return;
    }

    setIsSuspending(true);

    try {
      const selectedGateways = records
        .filter(record => selectedRows.includes(record.id))
        .map(record => record.gateway.gatewaySN);

      const suspendPromises = selectedGateways.map(gatewaySN => 
        apiClient.post(`${apiBaseUrl}/cms/update`, {
          gatewaySN: gatewaySN,
          statusId: 5 
        })
      );

      await Promise.all(suspendPromises);
      
      setSelectedRows([]);
      alert("Selected gateways have been suspended successfully");
      
      window.location.reload();
    } catch (error) {
      console.error("Error suspending gateways:", error);
      alert("Failed to suspend gateways. Please try again.");
    } finally {
      setIsSuspending(false);
    }
  };
  const toggleRow = (id) => {
    if (id === undefined) return;
    const newExpandedRows = new Set(expandedRows);
    if (newExpandedRows.has(id)) {
      newExpandedRows.delete(id);
    } else {
      newExpandedRows.add(id);
    }
    setExpandedRows(newExpandedRows);
  };

  if (!records || records.length === 0) {
    return (
      <div className="min-h-[300px] flex flex-col items-center justify-center p-8 bg-gray-50 rounded-lg border border-gray-200">
        <AlertCircle className="h-12 w-12 text-gray-400 mb-3" />
        <h3 className="text-lg font-medium text-gray-900 mb-1">No Records Found</h3>
        <p className="text-sm text-gray-500">There are currently no active subscription records to display.</p>
      </div>
    );
  }

  return (
    <div>
<div className="bg-white rounded-lg shadow">
      <table className="w-full">
        <thead>
          <tr className="border-b">
            <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Expand</th>
            <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Select</th>
            <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Index</th>
            <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Gateway SN</th>
            <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subscription Start</th>
            <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subscription End</th>
            <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Amount</th>
            <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Due</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">

          {records.map((record, index) => {
            const isExpanded = expandedRows.has(record.id);
            return (
              <React.Fragment key={record.id}>
                <tr className="hover:bg-gray-50 transition-colors">
                  <td className="px-6 py-4">
                    <button
                      onClick={() => toggleRow(record.id)}
                      className="text-gray-400 hover:text-gray-600"
                    >
                      {isExpanded ? (
                        <ChevronDown className="h-4 w-4" />
                      ) : (
                        <ChevronRight className="h-4 w-4" />
                      )}
                    </button>
                  </td>
                  <td className="px-6 py-4">
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(record.id)}
                      onChange={() => handleRowSelect(record.id)}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {index + 1 + (currentPage - 1) * pageSize}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900 font-medium">
                    {record.gateway.gatewaySN}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {new Date(record.subscriptionStart)
                      .toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      })
                      .toUpperCase()}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {new Date(record.subscriptionEnd)
                      .toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      })
                      .toUpperCase()}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {record.gateway.amountDue
                      ? `RM${record.gateway.amountDue.toFixed(2)}`
                      : "-"}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    <div className="flex items-center gap-2">
                      {record.gateway.paymentDueDate ? (
                        <>
                          <span
                            className={
                              record.gateway.subscriptionRenewalStatus === 4
                                ? "line-through"
                                : ""
                            }
                          >
                            {new Date(record.gateway.paymentDueDate)
                              .toLocaleString("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              })
                              .toUpperCase()}
                          </span>
                          {record.gateway.subscriptionRenewalStatus === 4 && (
                            <span className="text-green-600 font-medium">
                              PAID
                            </span>
                          )}
                        </>
                      ) : (
                        "-"
                      )}
                    </div>
                  </td>
                </tr>
                {isExpanded && (
                  <tr>
                    <td colSpan="8" className="px-6 py-4 bg-gray-50">
                      <div className="flex items-start space-x-4">
                        <div className="flex-shrink-0">
                          <div className="w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center">
                            <User className="h-5 w-5 text-blue-600" />
                          </div>
                        </div>
                        <div className="flex-grow">
                          <div className="grid grid-cols-2 gap-8">
                            <div>
                              <h4 className="text-sm font-medium text-gray-900">
                                Member Information
                              </h4>
                              <div className="mt-2 space-y-2">
                                <p className="text-sm text-gray-600">
                                  <span className="font-medium">Name:</span>{" "}
                                  {record.gateway.member.name}
                                </p>
                                <p className="text-sm text-gray-600">
                                  <span className="font-medium">
                                    IC Number:
                                  </span>{" "}
                                  {record.gateway.member.icNumber}
                                </p>
                                <p className="text-sm text-gray-600">
                                  <span className="font-medium">Phone:</span>{" "}
                                  {record.gateway.member.phoneNumber}
                                </p>
                              </div>
                            </div>
                            {record.contactPersons &&
                              record.contactPersons.length > 0 && (
                                <div>
                                  <h4 className="text-sm font-medium text-gray-900">
                                    Contact Persons
                                  </h4>
                                  <div className="mt-2 space-y-4">
                                    {record.contactPersons.map(
                                      (contact, idx) => (
                                        <div key={idx} className="space-y-1">
                                          <p className="text-sm text-gray-600">
                                            <span className="font-medium">
                                              Name:
                                            </span>{" "}
                                            {contact.name}
                                          </p>
                                          <p className="text-sm text-gray-600">
                                            <span className="font-medium">
                                              Phone:
                                            </span>{" "}
                                            {contact.contactNumber}
                                          </p>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      </div>
      <div className="flex justify-end mt-4">
      <button
          onClick={handleSuspend}
          disabled={selectedRows.length === 0 || isSuspending}
          className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200
            ${selectedRows.length === 0 || isSuspending
              ? 'bg-gray-300 cursor-not-allowed'
              : 'bg-red-600 hover:bg-red-700 text-white'
            }`}
        >
          {isSuspending ? (
            <>
              <span className="inline-block animate-spin mr-2">⌛</span>
              Suspending...
            </>
          ) : (
            `Suspend Selected (${selectedRows.length})`
          )}
        </button>
    </div>
    </div>
  );
}

export default ActiveTab;