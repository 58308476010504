import React, { useState, useEffect } from "react";
import { useAuth } from "../dataContext/AuthContext";
import apiClient from "../utils/axiosConfig";
import Sidebar from "../sidebar/sidebar";
import { ChevronDown, ChevronUp, FileText } from "lucide-react";

function CmsOrder() {
  const [orders, setOrders] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(1);
  const [loading, setLoading] = useState(false);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [generatingInvoice, setGeneratingInvoice] = useState(null);
  const [editingStatus, setEditingStatus] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [pendingStatusUpdate, setPendingStatusUpdate] = useState(null);
  const { organizationId, roles } = useAuth();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleStatusChange = (orderReference, newStatus) => {
    if (parseInt(newStatus) === 4) {
      setPendingStatusUpdate({
        orderReference,
        newStatus: parseInt(newStatus),
      });
      setShowConfirmModal(true);
    } else {
      // For other status changes, proceed normally
      handleUpdateStatus(orderReference, parseInt(newStatus));
    }
  };

  const ErrorMessage = () => {
    if (!showErrorMessage) return null;

    return (
      <div className="fixed top-4 right-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded z-50 flex justify-between items-center">
        <span>Cannot edit status of paid orders</span>
        <button
          onClick={() => setShowErrorMessage(false)}
          className="ml-4 text-red-700 hover:text-red-900"
        >
          ×
        </button>
      </div>
    );
  };

  const orderStatuses = [
    { id: 0, name: "All Orders" },
    { id: 1, name: "Created" },
    { id: 2, name: "PaymentPending" }, // Updated to match enum
    { id: 3, name: "PaymentFailed" }, // Updated to match enum
    { id: 4, name: "Paid" },
    { id: 5, name: "Processing" },
    { id: 6, name: "Cancelled" },
    { id: 7, name: "Refunded" },
  ];

  const handleUpdateStatus = async (orderReference, newStatus) => {
    if(!roles.includes("super_admin")){
      return; 
    }
    try {
      const response = await apiClient.post(
        `${apiBaseUrl}/order/update/${orderReference}`,
        newStatus,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      await fetchOrders(selectedStatus);
    } catch (error) {
      console.error("Full error response:", error.response?.data);
    } finally {
      setEditingStatus(null);
      setPendingStatusUpdate(null);
      setShowConfirmModal(false);
    }
  };

  const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
        <div className="bg-white rounded-lg p-6 max-w-md w-full m-4">
          <h2 className="text-xl font-bold mb-4">
            Confirm Status Change to Paid
          </h2>
          <p className="text-red-600 mb-6">
            Warning: Changing an order's status to Paid cannot be undone. Are
            you sure you want to proceed?
          </p>
          <div className="flex justify-end space-x-4">
            <button
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-100"
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
            >
              Confirm Change
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleGenerateInvoice = async (orderReference) => {
    setGeneratingInvoice(orderReference);
    try {
      const response = await apiClient.get(
        `${apiBaseUrl}/order/${orderReference}/generate`,
        { responseType: "blob" }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `invoice-${orderReference}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error generating invoice:", error);
    } finally {
      setGeneratingInvoice(null);
    }
  };

  const toggleRow = (orderId) => {
    const newExpandedRows = new Set(expandedRows);
    if (newExpandedRows.has(orderId)) {
      newExpandedRows.delete(orderId);
    } else {
      newExpandedRows.add(orderId);
    }
    setExpandedRows(newExpandedRows);
  };

  const fetchOrders = async (status) => {
    setLoading(true);
    try {
      const response = await apiClient.get(
        `${apiBaseUrl}/order?status=${status}&organizationId=${organizationId}`
      );
      if (response?.data?.data) {
        setOrders(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders(selectedStatus);
  }, [selectedStatus]);

  const getStatusColor = (status) => {
    const colors = {
      Created: "bg-gray-100",
      "Payment Pending": "bg-yellow-100",
      "Payment Failed": "bg-red-100",
      Paid: "bg-green-100",
      Processing: "bg-blue-100",
      Cancelled: "bg-red-100",
      Refunded: "bg-purple-100",
    };
    return colors[status] || "bg-gray-100";
  };

  const handleStatusClick = (order) => {
    if (order.status === "Paid") {
      setShowErrorMessage(true);
      // Auto-hide the message after 3 seconds
      setTimeout(() => {
        setShowErrorMessage(false);
      }, 3000);
    } else {
      setEditingStatus(order.id);
    }
  };
  const OrdersContent = () => (
    <div className="p-6 flex-1">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">Orders</h1>
        <div className="flex items-center space-x-4">
          <span className="text-sm text-gray-600">Filter by status:</span>
          <select
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(Number(e.target.value))}
            className="border border-gray-300 rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {orderStatuses.map((status) => (
              <option key={status.id} value={status.id}>
                {status.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      ) : orders.length === 0 ? (
        <div className="text-center py-12 text-gray-500">
          No orders found for the selected status.
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 rounded-lg">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Reference
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Invoice Number
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Invoice Due Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Amount
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Created At
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  User
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Gateways
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {orders.map((order) => (
                <React.Fragment key={order.id}>
                  <tr className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {order.reference}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {order.invoice.invoiceNumber}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {new Date(
                        order.invoice.invoiceDueDate
                      ).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {order.amount} {order.currency}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {editingStatus === order.id && order.status !== "Paid" ? (
                        <select
                        disabled={!roles.includes("super_admin")}
                          className="border border-gray-300 rounded px-2 py-1 text-sm w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                          defaultValue={
                            orderStatuses.find((s) => s.name === order.status)
                              ?.id
                          }
                          onChange={(e) => {
                            handleStatusChange(order.reference, e.target.value);
                            if (parseInt(e.target.value) !== 4) {
                              setEditingStatus(null);
                            }
                          }}
                        >
                          {orderStatuses
                            .filter((status) => status.id !== 0)
                            .map((status) => (
                              <option key={status.id} value={status.id}>
                                {status.name}
                              </option>
                            ))}
                        </select>
                      ) : (
                        <span
                          className={`px-2 py-1 text-sm rounded-full ${getStatusColor(
                            order.status
                          )} cursor-pointer hover:ring-2 hover:ring-blue-500`}
                          onClick={() => handleStatusClick(order)}
                        >
                          {order.status}
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {new Date(order.createdAt).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {order.user?.email}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {order.orderGateways.length}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <div className="flex space-x-4">
                        <button
                          onClick={() => toggleRow(order.id)}
                          className="text-blue-600 hover:text-blue-800 flex items-center"
                        >
                          {expandedRows.has(order.id) ? (
                            <>
                              Hide Gateways{" "}
                              <ChevronUp className="ml-1 w-4 h-4" />
                            </>
                          ) : (
                            <>
                              View Gateways{" "}
                              <ChevronDown className="ml-1 w-4 h-4" />
                            </>
                          )}
                        </button>
                        <button
                          onClick={() => handleGenerateInvoice(order.reference)}
                          disabled={generatingInvoice === order.id}
                          className={`flex items-center ${
                            generatingInvoice === order.id
                              ? "text-gray-400 cursor-not-allowed"
                              : "text-green-600 hover:text-green-800"
                          }`}
                        >
                          <FileText className="w-4 h-4 mr-1" />
                          {generatingInvoice === order.id
                            ? "Generating..."
                            : "Generate Invoice"}
                        </button>
                      </div>
                    </td>
                  </tr>
                  {expandedRows.has(order.id) && (
                    <tr>
                      <td colSpan="7" className="px-6 py-4 bg-gray-50">
                        <div className="border rounded-lg p-4">
                          <h4 className="font-medium mb-2">Order Gateways</h4>
                          <table className="min-w-full">
                            <thead>
                              <tr>
                                <th className="text-left text-xs font-medium text-gray-500 uppercase">
                                  Gateway Serial Number
                                </th>
                                <th className="text-left text-xs font-medium text-gray-500 uppercase">
                                  Price
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {order.orderGateways.map((gateway, index) => (
                                <tr key={index}>
                                  <td className="py-2 text-sm text-gray-900">
                                    {gateway.gatewaySN}
                                  </td>
                                  <td className="py-2 text-sm text-gray-900">
                                    {gateway.price}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto">
          <OrdersContent />
        </main>
      </div>
      <ErrorMessage />
      <ConfirmationModal
        isOpen={showConfirmModal}
        onClose={() => {
          setEditingStatus(null);
          setPendingStatusUpdate(null);
          setShowConfirmModal(false);
        }}
        onConfirm={() => {
          if (pendingStatusUpdate) {
            handleUpdateStatus(
              pendingStatusUpdate.orderReference,
              pendingStatusUpdate.newStatus
            );
          }
        }}
      />
    </div>
  );
}

export default CmsOrder;
