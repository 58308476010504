import { Navigate } from "react-router-dom";
import { useAuth } from "../dataContext/AuthContext";
import { useState, useEffect } from "react";

const ProtectedRoute = ({ children, requiredRoles }) => {
    const { roles } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
        const checkAuthorization = () => {
            const token = localStorage.getItem("jwtToken");
            const userRoles = Array.isArray(roles) ? roles : [];
            
            if (!token) {
                setIsAuthorized(false);
                setIsLoading(false);
                return;
            }

            // Check if roles are available and match required roles
            if (userRoles.length > 0) {
                setIsAuthorized(requiredRoles.some(role => userRoles.includes(role)));
                setIsLoading(false);
            }
        };

        checkAuthorization();
    }, [roles, requiredRoles]);

    // Show nothing while checking authorization
    if (isLoading) {
        return null; // Or return a loading spinner component
    }

    return isAuthorized ? children : <Navigate to="/unauthorized" />;
};

export default ProtectedRoute;