import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./login_page/login";
import Settings from "./settings/settings";
import Dashboard from "./dashboard_page/dashboard";
import WhiteList from "./gateway_whitelist/whitelist_page";
import Warranty from "./warranty/warranty";
import DeviceInfoPage from "./device_info/device_info";
import AnalyticsPage from "./analytics/analytics_page";
import GatewayConnectivityLogsPage from "./analytics/gateway_connectivity_logs";
import ProtectedRoute from "./components/ProtectedRoute";
import GatewayStatusPage from "./analytics/gateway_status_page";
import { GatewayDataProvider } from "./dataContext/GatewayDataContext";
import GatewayImageUpload from "./gateway_image/upload_image";
import UnauthorizedPage from "./unauthorize_page";
import CmsMainPage from "./cms/cms_main_page";
import { AuthProvider } from "./dataContext/AuthContext";
import CmsOrder from "./cms/cms_order";
import SubscriptionModelPage from "./cms/manage_subscription_model";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
    <GatewayDataProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/settings"
            element={
              <ProtectedRoute requiredRoles={['super_admin']}>
                <Settings/>
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute requiredRoles={['super_admin', 'cms_admin', 'finance']}> 
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/whitelist"
            element={
              <ProtectedRoute requiredRoles={['super_admin']}>
                <WhiteList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/warranty"
            element={
              <ProtectedRoute requiredRoles={['super_admin']}>
                <Warranty />
              </ProtectedRoute>
            }
          />
          <Route
            path="/device"
            element={
              <ProtectedRoute  requiredRoles={['super_admin']}>
                <DeviceInfoPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/analytics"
            element={
              <ProtectedRoute  requiredRoles={['super_admin']}>
                <AnalyticsPage />
              </ProtectedRoute>
            }
          >
            <Route path="connectivity" element={<GatewayConnectivityLogsPage/>} />
          </Route>
          <Route
          path="/gatewayConnectStatus"
          element={
            <ProtectedRoute  requiredRoles={['super_admin']}>
              <GatewayStatusPage/>
            </ProtectedRoute>
          }/>
          <Route
          path="/image_upload"
          element={
            <ProtectedRoute  requiredRoles={['super_admin', 'cms_admin']}>
              <GatewayImageUpload/>
            </ProtectedRoute>
          }/>
          <Route
          path="/cms"
          element={
            <ProtectedRoute  requiredRoles={['super_admin', 'cms_admin']}>
              <CmsMainPage/>  
            </ProtectedRoute>
          }/>
           <Route
          path="/cms/orders"
          element={
            <ProtectedRoute  requiredRoles={['super_admin', 'cms_admin']}>
              <CmsOrder/>  
            </ProtectedRoute>
          }/>
          <Route path="/unauthorized" element={<UnauthorizedPage />} />
          <Route
          path="/cms/model"
          element={
            <ProtectedRoute  requiredRoles={['super_admin']}>
              <SubscriptionModelPage/>  
            </ProtectedRoute>
          }/>
          <Route path="/unauthorized" element={<UnauthorizedPage />} />
        </Routes>
        
      </Router>
    </GatewayDataProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
