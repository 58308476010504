import React, { createContext, useContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();

function getUserRolesFromToken(token) {
  if (!token) return [];

  try {
    const decodedToken = jwtDecode(token);
    const roles =
      decodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];
    return Array.isArray(roles) ? roles : roles ? [roles] : [];
  } catch (error) {
    console.error("Invalid token", error);
    return [];
  }
}

function getOrganizationIdFromToken(token) {
  if (!token) return 0;

  try {
    const decodedToken = jwtDecode(token);
    // Adjust this based on your actual token structure
    return decodedToken.organizationId || 0;
  } catch (error) {
    console.error("Error decoding organizationId from token", error);
    return 0;
  }
}

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [roles, setRoles] = useState([]);
  const [organizationId, setOrganizationId] = useState(() => {
    // Initialize from localStorage
    const savedOrgId = localStorage.getItem("organizationId");
    return savedOrgId ? parseInt(savedOrgId, 10) : 0;
  });
  const [userId, setUserId] = useState(() => {
    // Initialize from localStorage
    const savedUserId = localStorage.getItem("userId");
    return savedUserId ? parseInt(savedUserId, 10) : 0;
  });

  const updateToken = (newToken) => {
    setToken(newToken);
    localStorage.setItem("jwtToken", newToken);
    const decodedRoles = getUserRolesFromToken(newToken);
    setRoles(decodedRoles);
    
    // Optionally, extract organizationId from token if it's stored there
    const orgId = getOrganizationIdFromToken(newToken);
    if (orgId) {
      updateOrganizationId(orgId);
    }
  };

  const updateOrganizationId = (id) => {
    setOrganizationId(id);
    localStorage.setItem("organizationId", id.toString());
  };

  const updateUserId = (id) => {
    setUserId(id);
    localStorage.setItem("userId", id.toString());
  };

  useEffect(() => {
    const savedToken = localStorage.getItem("jwtToken");
    const savedOrgId = localStorage.getItem("organizationId");
    const savedUserId = localStorage.getItem("userId");

    if (savedToken) {
      setToken(savedToken);
      const decodedRoles = getUserRolesFromToken(savedToken);
      setRoles(decodedRoles);
    }

    if (savedOrgId) {
      setOrganizationId(parseInt(savedOrgId, 10));
    }

    if (savedUserId) {
      setUserId(parseInt(savedUserId, 10));
    }
  }, []);

  const logout = () => {
    setToken(null);
    setRoles([]);
    setOrganizationId(0);
    setUserId(0);
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("organizationId");
    localStorage.removeItem("userId");
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        roles,
        organizationId,
        userId,
        updateToken,
        updateOrganizationId,
        updateUserId,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);