import React, { useState, useEffect } from "react";
import { useAuth } from "../dataContext/AuthContext";
import apiClient from "../utils/axiosConfig";

const CmsActivationModal = ({isReload, setIsReload}) => {
  const { organizationId } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [formData, setFormData] = useState({
    gatewaySN: "",
    organizationId: organizationId,
    cmsAccountNumber: "",
    address: "",
    subscriptionModelId: "",
    person1: {
      name: "",
      phoneNumber: "",
    },
    person2: {
      name: "",
      phoneNumber: "",
    },
    member: {
      name: "",
      phoneNumber: "",
      icNumber: "",
      email: "",
    },
  });

  const handleClose = () => {
    setFormData({
        gatewaySN: "",
        organizationId: organizationId,
        cmsAccountNumber: "",
        address: "",
        subscriptionModelId: "",
        person1: {
          name: "",
          phoneNumber: "",
        },
        person2: {
          name: "",
          phoneNumber: "",
        },
        member: {
          name: "",
          phoneNumber: "",
          icNumber: "",
          email: "",
        },
      }); 
      setIsOpen(false);
  }

  const getFieldError = (fieldPath) => {
    return fieldErrors[fieldPath];
  };

  const validateForm = () => {
    const errors = {};

    // Gateway SN validation
    if (!formData.gatewaySN) {
      errors.gatewaySN = "Gateway SN is required";
    } else if (!/^[A-Za-z0-9-]+$/.test(formData.gatewaySN)) {
      errors.gatewaySN =
        "Gateway SN can only contain letters, numbers, and hyphens";
    }

    // CMS Account Number validation
    if (!formData.cmsAccountNumber) {
      errors.cmsAccountNumber = "CMS Account Number is required";
    }

    // Address validation
    if (!formData.address.trim()) {
      errors.address = "Address is required";
    }

    // Subscription Model ID validation
    if (!formData.subscriptionModelId) {
      errors.subscriptionModelId = "Subscription Model ID is required";
    } else if (isNaN(formData.subscriptionModelId)) {
      errors.subscriptionModelId = "Subscription Model ID must be a number";
    }

    // Person 1 validation
    if (!formData.person1.name.trim()) {
      errors["person1.name"] = "Contact Person 1 Name is required";
    }
    if (!formData.person1.phoneNumber) {
      errors["person1.phoneNumber"] =
        "Contact Person 1 Phone Number is required";
    } else if (!/^\+?[\d-]{8,}$/.test(formData.person1.phoneNumber)) {
      errors["person1.phoneNumber"] = "Invalid phone number format";
    }

    // Person 2 validation (optional)
    if (
      formData.person2.phoneNumber &&
      !/^\+?[\d-]{8,}$/.test(formData.person2.phoneNumber)
    ) {
      errors["person2.phoneNumber"] = "Invalid phone number format";
    }

    // Member validation
    if (!formData.member.name.trim()) {
      errors["member.name"] = "Member Name is required";
    }
    if (!formData.member.phoneNumber) {
      errors["member.phoneNumber"] = "Member Phone Number is required";
    } else if (!/^\+?[\d-]{8,}$/.test(formData.member.phoneNumber)) {
      errors["member.phoneNumber"] = "Invalid phone number format";
    }
    if (!formData.member.icNumber) {
      errors["member.icNumber"] = "IC Number is required";
    }
    if (!formData.member.email) {
      errors["member.email"] = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.member.email)) {
      errors["member.email"] = "Invalid email format";
    }

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes(".")) {
      const [section, field] = name.split(".");
      setFormData((prev) => ({
        ...prev,
        [section]: {
          ...prev[section],
          [field]: value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false);

    if (!validateForm()) {
      setError("Please correct the errors in the form");
      return;
    }

    setIsLoading(true);

    try {
      await apiClient.post(`${apiBaseUrl}/cms/activate`, formData);
      setSuccess(true);
      // Reset form
      setFormData({
        gatewaySN: "",
        organizationId: organizationId,
        cmsAccountNumber: "",
        address: "",
        subscriptionModelId: "",
        person1: { name: "", phoneNumber: "" },
        person2: { name: "", phoneNumber: "" },
        member: { name: "", phoneNumber: "", icNumber: "", email: "" },
      });
      setTimeout(() => {
        handleClose();
        setSuccess(false);
        setIsReload(true);
      }, 2000);
    } catch (err) {
      if (err.response?.data?.errors) {
        // Handle structured API validation errors
        const apiErrors = err.response.data.errors;
        const formattedErrors = {};
        Object.entries(apiErrors).forEach(([key, value]) => {
          formattedErrors[key] = Array.isArray(value) ? value[0] : value;
        });
        setFieldErrors(formattedErrors);
        setError("Please correct the validation errors");
      } else if (err.response?.status === 401) {
        setError("Unauthorized. Please log in again.");
      } else if (err.response?.status === 403) {
        setError("You do not have permission to perform this action.");
      } else if (err.response?.status === 404) {
        setError("The requested resource was not found.");
      } else if (err.response?.status >= 500) {
        setError("A server error occurred. Please try again later.");
      } else {
        setError(err.response?.data?.message || "An unexpected error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getInputClassName = (fieldName) => {
    return `w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 
      ${
        fieldErrors[fieldName]
          ? "border-red-500 focus:ring-red-500"
          : "border-gray-300"
      }`;
  };

  return (
    <div>
      <button
        onClick={() => setIsOpen(true)}
        className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg text-sm font-medium transition-colors duration-200"
      >
        Activate New CMS
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-2xl w-full mx-4 max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-gray-900">
                CMS Activation Form
              </h2>
              <button
                onClick={() => handleClose()}
                className="text-gray-500 hover:text-gray-700"
              >
                ✕
              </button>
            </div>

            {error && (
              <div className="bg-red-50 text-red-700 p-4 rounded-lg mb-4">
                {error}
              </div>
            )}

            {success && (
              <div className="bg-green-50 text-green-700 p-4 rounded-lg mb-4">
                CMS activated successfully!
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Gateway SN
                  </label>
                  <input
                    type="text"
                    name="gatewaySN"
                    value={formData.gatewaySN}
                    onChange={handleChange}
                    className={getInputClassName("gatewaySN")}
                    required
                  />
                  {getFieldError("gatewaySN") && (
                    <p className="mt-1 text-sm text-red-600">
                      {getFieldError("gatewaySN")}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    CMS Account Number
                  </label>
                  <input
                    type="text"
                    name="cmsAccountNumber"
                    value={formData.cmsAccountNumber}
                    onChange={handleChange}
                    className={getInputClassName("cmsAccountNumber")}
                    required
                  />
                  {getFieldError("cmsAccountNumber") && (
                    <p className="mt-1 text-sm text-red-600">
                      {getFieldError("cmsAccountNumber")}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Address
                  </label>
                  <textarea
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    className={getInputClassName("address")}
                    required
                    rows="3"
                  />
                  {getFieldError("address") && (
                    <p className="mt-1 text-sm text-red-600">
                      {getFieldError("address")}
                    </p>
                  )}
                </div>

                <CmsSubscriptionModelDropdown
                  value={formData.subscriptionModelId}
                  onChange={handleChange}
                  error={getFieldError("subscriptionModelId")}
                />

                <div className="border-t pt-4">
                  <h3 className="text-lg font-medium text-gray-900 mb-4">
                    Contact Person 1
                  </h3>
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Name
                      </label>
                      <input
                        type="text"
                        name="person1.name"
                        value={formData.person1.name}
                        onChange={handleChange}
                        className={getInputClassName("person1.name")}
                        required
                      />
                      {getFieldError("person1.name") && (
                        <p className="mt-1 text-sm text-red-600">
                          {getFieldError("person1.name")}
                        </p>
                      )}
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        name="person1.phoneNumber"
                        value={formData.person1.phoneNumber}
                        onChange={handleChange}
                        className={getInputClassName("person1.phoneNumber")}
                        required
                      />
                      {getFieldError("person1.phoneNumber") && (
                        <p className="mt-1 text-sm text-red-600">
                          {getFieldError("person1.phoneNumber")}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="border-t pt-4">
                  <h3 className="text-lg font-medium text-gray-900 mb-4">
                    Contact Person 2 (Optional)
                  </h3>
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Name
                      </label>
                      <input
                        type="text"
                        name="person2.name"
                        value={formData.person2.name}
                        onChange={handleChange}
                        className={getInputClassName("person2.name")}
                      />
                      {getFieldError("person2.name") && (
                        <p className="mt-1 text-sm text-red-600">
                          {getFieldError("person2.name")}
                        </p>
                      )}
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        name="person2.phoneNumber"
                        value={formData.person2.phoneNumber}
                        onChange={handleChange}
                        className={getInputClassName("person2.phoneNumber")}
                      />
                      {getFieldError("person2.phoneNumber") && (
                        <p className="mt-1 text-sm text-red-600">
                          {getFieldError("person2.phoneNumber")}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="border-t pt-4">
                  <h3 className="text-lg font-medium text-gray-900 mb-4">
                    Member Information
                  </h3>
                  <div className="grid grid-cols-1 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Name
                      </label>
                      <input
                        type="text"
                        name="member.name"
                        value={formData.member.name}
                        onChange={handleChange}
                        className={getInputClassName("member.name")}
                        required
                      />
                      {getFieldError("member.name") && (
                        <p className="mt-1 text-sm text-red-600">
                          {getFieldError("member.name")}
                        </p>
                      )}
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        name="member.phoneNumber"
                        value={formData.member.phoneNumber}
                        onChange={handleChange}
                        className={getInputClassName("member.phoneNumber")}
                        required
                      />
                      {getFieldError("member.phoneNumber") && (
                        <p className="mt-1 text-sm text-red-600">
                          {getFieldError("member.phoneNumber")}
                        </p>
                      )}
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        IC Number
                      </label>
                      <input
                        type="text"
                        name="member.icNumber"
                        value={formData.member.icNumber}
                        onChange={handleChange}
                        className={getInputClassName("member.icNumber")}
                        required
                      />
                      {getFieldError("member.icNumber") && (
                        <p className="mt-1 text-sm text-red-600">
                          {getFieldError("member.icNumber")}
                        </p>
                      )}
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Email
                      </label>
                      <input
                        type="email"
                        name="member.email"
                        value={formData.member.email}
                        onChange={handleChange}
                        className={getInputClassName("member.email")}
                        required
                      />
                      {getFieldError("member.email") && (
                        <p className="mt-1 text-sm text-red-600">
                          {getFieldError("member.email")}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-end space-x-4 pt-4">
                <button
                  type="button"
                  onClick={() => handleClose()}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors duration-200"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg transition-colors duration-200 
        ${isLoading ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700"}`}
                >
                  {isLoading ? "Activating..." : "Activate CMS"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

const CmsSubscriptionModelDropdown = ({ value, onChange, error }) => {
  const [subscriptionModels, setSubscriptionModels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadError, setLoadError] = useState("");
  const { organizationId } = useAuth();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchSubscriptionModels = async () => {
      setIsLoading(true);
      setLoadError("");

      try {
        const response = await apiClient.get(
          `${apiBaseUrl}/cms/subscriptionModel`
        );
        setSubscriptionModels(response.data.data);
      } catch (err) {
        setLoadError("Failed to load subscription models. Please try again.");
        console.error("Error fetching subscription models:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubscriptionModels();
  }, [organizationId]);

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        Subscription Model
      </label>
      <select
        value={value}
        onChange={onChange}
        name="subscriptionModelId"
        className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 
            ${error ? "border-red-500 focus:ring-red-500" : "border-gray-300"}
            ${isLoading ? "bg-gray-100" : ""}`}
        disabled={isLoading}
      >
        <option value="">Select a subscription model</option>
        {subscriptionModels.map((model) => (
          <option key={model.id} value={model.id}>
            {model.name} - {model.durationInMonths} months (RM
            {model.price.toFixed(2)})
          </option>
        ))}
      </select>

      {error && <p className="mt-1 text-sm text-red-600">{error}</p>}

      {loadError && <p className="mt-1 text-sm text-red-600">{loadError}</p>}

      {value && subscriptionModels.length > 0 && (
        <div className="mt-2 text-sm text-gray-600">
          {(() => {
            const selected = subscriptionModels.find(
              (model) => model.id.toString() === value.toString()
            );
            if (selected) {
              return (
                <div className="bg-gray-50 p-3 rounded-md">
                  <p className="font-medium">{selected.name}</p>
                  <p className="mt-1">{selected.description}</p>
                  <p className="mt-1">
                    Duration: {selected.durationInMonths} months
                  </p>
                  <p className="mt-1">Price: RM{selected.price.toFixed(2)}</p>
                </div>
              );
            }
          })()}
        </div>
      )}
    </div>
  );
};

export default CmsActivationModal;
