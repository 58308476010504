import React, { useState, useEffect } from "react";
import { Pencil, Trash2, Plus } from "lucide-react";
import Sidebar from "../sidebar/sidebar";
import { useNavigate } from "react-router-dom";
import apiClient from "../utils/axiosConfig";
import { Modal } from "./cms_add_subscription_model_modal";
function SubscriptionModelPage() {
  const [subscriptionModels, setSubscriptionModels] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentModel, setCurrentModel] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    price: "",
    durationInMonths: "",
  });
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    fetchSubscriptionModels();
  }, []);

  const fetchSubscriptionModels = async () => {
    try {
      const response = await apiClient.get(
        `${apiBaseUrl}/cms/subscriptionModel?organizationId=${1}`
      );

      if (response) {
        setSubscriptionModels(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching subscription models:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const openCreateModal = () => {
    setFormData({
      name: "",
      description: "",
      price: "",
      durationInMonths: "",
    });
    setIsCreateModalOpen(true);
  };
  
  const handleCreate = async () => {
    try {
      const requestBody = {
        name: formData.name,
        description: formData.description,
        price: parseFloat(formData.price),
        durationInMonths: parseInt(formData.durationInMonths),
      };

      const response = await apiClient.post(
        `${apiBaseUrl}/subscription/create`,
        requestBody
      );
      if (response.data) {
        setIsCreateModalOpen(false);
        await fetchSubscriptionModels();
        setFormData({
          name: "",
          description: "",
          price: "",
          durationInMonths: "",
        });
      }
    } catch (error) {
      console.error("Error creating subscription model:", error);
    }
  };

  const handleCreateModalClose = () => {
    setFormData({
      name: "",
      description: "",
      price: "",
      durationInMonths: "",
    });
    setIsCreateModalOpen(false);
  }

  const handleUpdate = async () => {
    try {
      const requestBody = {
        id: currentModel.id,
        name: formData.name,
        description: formData.description,
        price: parseFloat(formData.price),
        durationInMonths: parseInt(formData.durationInMonths),
      };

      const response = await apiClient.post(
        `${apiBaseUrl}/subscription/update`,
        requestBody
      );

      if (response.data.data) {
        setIsEditModalOpen(false);
        fetchSubscriptionModels();
      }
    } catch (error) {
      console.error("Error updating subscription model:", error);
    }
  };

  const handleDelete = async (id) => {
    if (
      window.confirm("Are you sure you want to delete this subscription model?")
    ) {
      try {
        const response = await apiClient.post(
          `${apiBaseUrl}/subscription/delete?modelId=${id}`
        );
        if (response.data) {
          await fetchSubscriptionModels(); // Added await
        }
      } catch (error) {
        console.error("Error deleting subscription model:", error);
      }
    }
  };

  const openEditModal = (model) => {
    setCurrentModel(model);
    setFormData({
      name: model.name,
      description: model.description,
      price: model.price.toString(),
      durationInMonths: model.durationInMonths.toString(),
    });
    setIsEditModalOpen(true);
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1">
        {/* Top Navigation */}
        <div className="p-4">
          <button
            onClick={() => navigate("/cms")}
            className="bg-blue-500 text-white px-4 py-2 rounded text-sm"
          >
            Back to CMS
          </button>
        </div>

        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold">Subscription Models</h1>
            <button
              onClick={openCreateModal}
              className="bg-blue-500 text-white px-4 py-2 rounded-md flex items-center gap-2 hover:bg-blue-600"
            >
              <Plus size={16} />
              Add New Model
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {subscriptionModels.map((model) => (
              <div key={model.id} className="bg-white rounded-lg shadow-md">
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2">{model.name}</h3>
                  <p className="text-gray-600 mb-4">{model.description}</p>
                  <p className="text-lg font-bold text-blue-600">
                    ${model.price.toFixed(2)} / {model.durationInMonths} months
                  </p>
                </div>
                <div className="border-t px-6 py-3 flex justify-end gap-2">
                  <button
                    onClick={() => openEditModal(model)}
                    className="p-2 text-gray-600 hover:text-blue-600 rounded-md"
                  >
                    <Pencil size={16} />
                  </button>
                  <button
                    onClick={() => handleDelete(model.id)}
                    className="p-2 text-gray-600 hover:text-red-600 rounded-md"
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* Create Modal */}
          <Modal
            isOpen={isCreateModalOpen}
            onClose={() => handleCreateModalClose()}
            title="Create New Subscription Model"
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleCreate();
              }}
            >
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Description
                </label>
                <input
                  type="text"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Price
                </label>
                <input
                  type="number"
                  name="price"
                  value={formData.price}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Duration (months)
                </label>
                <input
                  type="number"
                  name="durationInMonths"
                  value={formData.durationInMonths}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div className="flex justify-end gap-2 mt-6">
                <button
                  type="button"
                  onClick={() => setIsCreateModalOpen(false)}
                  className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                >
                  Create
                </button>
              </div>
            </form>
          </Modal>

          {/* Edit Modal */}
          <Modal
            isOpen={isEditModalOpen}
            onClose={() => {
              setFormData({
                name: "",
                description: "",
                price: "",
                durationInMonths: "",
              });
              setIsEditModalOpen(false);
            }}
            title="Edit Subscription Model"
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleUpdate();
              }}
            >
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Description
                </label>
                <input
                  type="text"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Price
                </label>
                <input
                  type="number"
                  name="price"
                  value={formData.price}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Duration (months)
                </label>
                <input
                  type="number"
                  name="durationInMonths"
                  value={formData.durationInMonths}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div className="flex justify-end gap-2 mt-6">
                <button
                  type="button"
                  onClick={() => setIsEditModalOpen(false)}
                  className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                >
                  Save Changes
                </button>
              </div>
            </form>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionModelPage;
